import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import { siteMetadata } from "../../gatsby-config";
import { useLocation } from '@reach/router';

const SEO = ({ seo = { titleNoTrail: false } }) => {
  const location = useLocation();
  const { strapiGlobal } = useStaticQuery(query);
  const { defaultSeo, siteName, favicon } = strapiGlobal;

  // Merge default and page-specific SEO values
  const fullSeo = { ...defaultSeo, ...seo };

  const getMetaTags = () => {
    const tags = [];

    if (fullSeo.metaTitle) {
      tags.push(
        {
          property: "og:title",
          content: fullSeo.metaTitle,
        },
        {
          name: "twitter:title",
          content: fullSeo.metaTitle,
        }
      );
    }
    if (fullSeo.metaDescription) {
      tags.push(
        {
          name: "description",
          content: fullSeo.metaDescription && fullSeo.metaDescription.length > 155 ? (fullSeo.metaDescription || "").slice(0, 152) + '...' : fullSeo.metaDescription,
        },
        {
          property: "og:description",
          content: fullSeo.metaDescription,
        },
        {
          name: "twitter:description",
          content: fullSeo.metaDescription,
        }
      );
    }
    if (fullSeo.shareImage) {
      const imageUrl =
        (process.env.GATSBY_ROOT_URL || "http://localhost:8000") +
        fullSeo.shareImage.publicURL;
      tags.push(
        {
          name: "image",
          content: imageUrl,
        },
        {
          property: "og:image",
          content: imageUrl,
        },
        {
          name: "twitter:image",
          content: imageUrl,
        }
      );
    }
    if (fullSeo.article) {
      tags.push({
        property: "og:type",
        content: "article",
      });
    }
    tags.push({ name: "twitter:card", content: "summary_large_image" });

    return tags;
  };

  const noTrail = fullSeo.metaTitle.split('|').length > 1
    || (`${fullSeo.metaTitle} | ${siteName}`.length > 65)
    || seo.titleNoTrail === true;

  const metaTags = getMetaTags();

  return (
    <Helmet
      title={fullSeo.metaTitle.length > 65 && fullSeo.metaTitle.split('|').length > 1 ? fullSeo.metaTitle.split('|')[0] : fullSeo.metaTitle}
      titleTemplate={noTrail ? `%s` : `%s | ${siteName}`}
      link={[
        {
          rel: "icon",
          href: favicon.publicURL,
        },
        {
          rel: 'canonical',
          href: seo.canonical || (location && location.pathname ? siteMetadata.siteUrl + location.pathname : siteMetadata.siteUrl)
        }
      ]}
      meta={metaTags}
    >
      {fullSeo && fullSeo.scripts && [
        ...fullSeo.scripts,
        {
          type: "application/ld+json",
          content: `{
             "@context": "https://schema.org",
              "@type": "Store",
              "name": "L'Atelier du Tote Bag",
              "url": "https://latelierdutotebag.com/",
              "image": "https://latelierdutotebag.com/static/e528c1c1c237747e3250b318ce0bc0b1/ca0ed/Logo-latelierdutotebag.webp",
              "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.9",
                "bestRating": "5",
                "ratingCount": "550"
              }
          }`
        },
      ].map((script, i) => (
        <script key={i} type={script.type}>
          {script.content}
        </script>
      ))}
    </Helmet>
  );
};

export default SEO;

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.string,
  article: PropTypes.bool,
};

SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  article: false,
};

const query = graphql`
  query {
    strapiGlobal {
      siteName
      favicon {
        localFile {
          publicURL
        }
      }
      defaultSeo {
        metaTitle
        metaDescription
        shareImage {
          localFile {
            publicURL
          }
        }
      }
    }
  }
`;
