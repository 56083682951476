import React from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import { categoryParams } from "../../../node-category";
import Category from "../../templates/category";

const Breadcrumbs = ({ category, size = "lg" }) => {
  const data = useStaticQuery(query);
  if (!category.strapiId && category.id) category.strapiId = category.id;
  else if (!category.id) {
    return (<></>);
  }
  return (
    <nav className="flex flex-wrap items-center">
      <Link to="/" className={`uppercase text-gray-400 underline text-${size}`}>
        Accueil
      </Link>
      {displayCategory(data.allStrapiCategory.nodes, category.strapiId, size)}
    </nav>
  );
};

function displayCategory(categories, categoryId, size) {
  var prev;
  const category = categories.find((c) => c.strapiId === categoryId);
  if (category) {
    if (category.strapiParent) {
      prev = displayCategory(categories, category.strapiParent.id, size);
    }
    return (
      <>
        {!category.strapiParent && (
          <span className="mx-2 text-gray-400">/</span>
        )}
        {prev}
        <span
          // href={`${categoryParams.urlPrefix}${category.slug}`}
          className={`uppercase text-gray-400 underline text-${size}`}
        >
          {category.name}&nbsp;/&nbsp;
        </span>
      </>
    );
  }
  return <></>;
}

const query = graphql`
  query {
    allStrapiCategory {
      nodes {
        slug
        id
        name
        strapiId
        strapiParent {
          id
        }
        strapiChildren{
          id
        }
        products {
          id
        }
      }
    }
  }
`;

export default Breadcrumbs;
