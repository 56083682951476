import { graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import React, { useState } from "react";
import Markdown from "react-markdown";
import Slider from "react-slick";
import FrenchFlag from "../assets/icons/french.svg";
import Breadcrumbs from "../components/breadcrumbs/breadcrumbs";
import Layout from "../components/layout";
import ProductList from "../components/product-list/product-list";
import SeparatorComponent from "../components/separator/separator";

export const query = graphql`
  query ProductQuery($slug: String!) {
    check: file( relativePath: { eq: "check-1.png" } ) {
      childImageSharp {
        gatsbyImageData(width: 17) 
      }
    }
    iconline1: file( relativePath: { eq: "devis-en-24h pour votre totebag.png" } ) {
      childImageSharp {
        gatsbyImageData(width: 64, height: 64) 
      }
    }
    iconline2: file( relativePath: { eq: "livraison 7 jours.png" } ) {
      childImageSharp {
        gatsbyImageData(width: 64, height: 64) 
      }
    }
    iconline3: file( relativePath: { eq: "vos totebags livrés gratuitement.png" } ) {
      childImageSharp {
        gatsbyImageData(width: 64, height: 64) 
      }
    }
    
    strapiProduct(slug: { eq: $slug }) {
      description
      id
      price
      short_description
      slug
      status
      title
      metatitle
      metadescription
      image {
        localFile {
          childImageSharp {
            gatsbyImageData(width: 500) 
          }
        }
      }
      variations {
        attributs_couleur {
          Nom
          valeur
          id
        }
        no_stock
        id
        Image {
          localFile {
            childImageSharp {
              gatsbyImageData(width: 500) 
            }
          }
        }
      }
      attributs_quantites {
        Nom
        id
      }
      attributs_personnalisations {
        Nom
        id
      }
      attributs_marquages {
        Nom
        id
      }
      attributs_compositions {
        Nom
        id
      }
      attributs_anses {
        Nom
        id
      }
      categories {
        name
        parent
        slug
        id
      }
    }
    allStrapiProduct(
      filter: { slug: { ne: $slug }, status: { eq: "published" } }
      limit: 4
    ) {
      edges {
        node {
          slug
          status
          strapiId
          title
          categories {
            name
            id
            slug
          }
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(width: 230, height: 280) 
              }
            }
          }
        }
      }
    }
  }
`;

const Product = React.forwardRef(({ data }, ref) => {
  const [quantity, setQuantity] = useState("100");
  const [activeTab, setActiveTab] = useState(0);
  const [slideIndex, setSlideIndex] = useState(0);
  const [color, setColor] = useState(null);
  const [noStock, setNoStock] = useState(false);
  const [faces, setFaces] = useState(1);
  const [marquage, setMarquage] = useState(null);
  const [cartItem, setCartItem] = useState(null);
  const [showCall, setShowCall] = useState(false);

  const product = data.strapiProduct;
  const products = data.allStrapiProduct.edges.map((e) => e.node);
  const category =
    product.categories && product.categories.length && product.categories[0];
  const seo = {
    metaTitle: product.metatitle || product.title,
    metaDescription: product.metadescription || product.description,
    shareImage: product.image.localFile.childImageSharp.gatsbyImageData.images.fallback.src,
    article: false,
    scripts: [
      {
        type: "application/ld+json",
        content: `{
            "@context": "https://schema.org",
            "@type": "Product",
            "@id": "https://latelierdutotebag.com/${category.slug}/${product.slug}",
            "countryOfAssembly": "France",
            "countryOfLastProcessing": "France",
            "description": "${product.description}",
            "image": "${product.image.localFile.childImageSharp.gatsbyImageData.images.fallback.src}",
            "url": "https://latelierdutotebag.com/${category.slug}/${product.slug}",
            "alternateName": "${product.title.replace(/"/g, '')}",
            "brand": "L'atelier du Tote Bag",
            "aggregateRating": {
              "@type": "AggregateRating",
              "ratingValue": "4.9",
              "ratingCount": "495",
              "worstRating":"1",
              "bestRating":"5",
              "itemReviewed": {
                "@type": "Product",
                "name": "${product.title.replace(/"/g, '')}"
              }
            }
        }`
      },
      {
        type: "application/ld+json",
        content: `{
          "@context": "https://schema.org",
          "@type": "BreadcrumbList",
          "itemListElement":[
            {
              "@type": "ListItem",
              "position": 1,
              "item":{
                "@id": "https://latelierdutotebag.com",
                "name": "Accueil"
              }
            },
            ${product.categories
            .sort((p1, _p2) => !!p1.parent ? 1 : -1)
            .map((c, i) => `
            {
              "@type": "ListItem",
              "position": ${i + 2},
              "item":{
                "@id": "https://latelierdutotebag.com/${c.slug}",
                "name": "${c.name}"
              }
            },
            `).join('')}
            {
              "@type": "ListItem",
              "position": ${product.categories.length + 2},
              "item":{
                "@id": "https://latelierdutotebag.com/${category.slug}/${product.slug}",
                "name": "${product.title.replace(/"/g, "'")}"
              }
            }
          ]
        }`
      }
    ]
  };

  React.useEffect(() => {
    if (cartItem) {
      setCartItem(null);
    }

    const variations = product.variations
      .filter((v) => v.attributs_couleur);
    if (variations.length === 1) {
      slideRef.current.slickGoTo(1);
      setColor(variations[0].attributs_couleur);
    }
  }, undefined);

  const addToCart = (e) => {
    e.preventDefault();
    if (typeof window === 'undefined') {
      return false;
    }
    const variation = product.variations
      .find((v) => v.attributs_couleur.Nom === color);

    const newCartItem = { color, marquage, quantity, product, variation, faces };
    const currentCart = JSON.parse(localStorage.getItem("ttbgCart") || "[]");
    currentCart.push(newCartItem);
    localStorage.setItem("ttbgCart", JSON.stringify(currentCart));
    setCartItem(newCartItem);

    return false;
  };

  const updateQty = (e) => {
    setQuantity(e.target.value);
  };

  const updateFaces = (e) => {
    setFaces(+e.target.value);
  };

  const showTab = (value) => {
    setActiveTab(value);
  };

  const quantities = ["100", "250", "500", "1000"];
  const attributes = [
    ["Composition", "attributs_compositions"],
    // ['Couleurs', 'attributs_couleurs'],
    ["Personnalisation", "attributs_personnalisations", 1],
    ["Quantités", "attributs_quantites"],
    ["Anses", "attributs_anses"],
    ["Informations supplémentaires", "attributs_infos"],
    ["Marquages", "attributs_marquages", 1],
  ];
  const couleurs = product.variations.map((v) => v.attributs_couleur.Nom);

  const images = [
    { image: product.image, color: "", title: product.title },
    ...product.variations.map((v) => ({
      image: v.Image,
      color: v.attributs_couleur,
      title: `${product.title} ${v.attributs_couleur.Nom}`,
    })),
  ];

  const settings = {
    dots: false,
    infinite: false,
    lazyLoad: true,
    speed: 500,
    adaptiveHeight: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (current, next) => setSlideIndex(next),
  };

  const settings2 = {
    dots: false,
    infinite: false,
    lazyLoad: true,
    speed: 500,
    adaptiveHeight: false,
    slidesToShow: 4,
    slidesToScroll: 1,
    // beforeChange: (current, next) => setSlideIndex(next)
  };

  const slideRef = React.useRef();
  const slideRef2 = React.useRef();

  const isPrintInFrance = (categoryId) => {
    const printInFranceCategories = [
      12, // totebag
      9, // trousse
      18, // pochette
      15, // sac coton bio
      16, // pochette bio
      19, // cabas
      28 // jute
    ];
    return printInFranceCategories.indexOf(categoryId) >= 0;
  }

  return (
    <Layout seo={seo} showHero={false} productAdded={cartItem}>
      <div className="container my-6">
        <div className="flex flex-wrap">
          <div className="w-full md:w-6/12 md:pr-4">
            <Slider ref={slideRef} {...settings}>
              {images.map((image, index) => (
                <GatsbyImage
                  key={index}
                  alt={image.title}
                  image={getImage(image.image.localFile)}
                />
              ))}
            </Slider>
            <Slider ref={slideRef2} {...settings2}>
              {images.map((image, index) => (
                <div
                  key={index}
                  className="p-1"
                  onClick={(e) => {
                    slideRef.current.slickGoTo(index);
                    setColor(image.color);
                  }}
                >
                  <GatsbyImage
                    image={getImage(image.image.localFile)}
                    width="114"
                    height="140"
                    className="mr-1"
                    alt={image.title}
                  />
                </div>
              ))}
            </Slider>
            {isPrintInFrance(category.id) && (
              <div className="flex justify-center">
                <div className="border-3 border-blue text-center p-4 mt-2">
                  <div className="m-0 text-lg font-semibold text-blue-hover uppercase">Le saviez-vous ?</div>
                  <p className="m-0 text-black">L'ensemble de nos sacs sont imprimés<br /> dans nos ateliers en france <FrenchFlag className="w-6 h-6 inline-block" /></p>
                </div>
              </div>
            )}
            <div className='mt-4'>
              <script src="https://static.elfsight.com/platform/platform.js" async></script>
              <div className="elfsight-app-6f9b1e67-957e-492f-bf83-5a329feabc6c" data-elfsight-app-lazy></div>
            </div>
          </div>
          <div className="w-full md:w-6/12 md:pl-4">
            <form
              onSubmit={addToCart}
              name="ajouter-panier"
            >
              <Breadcrumbs category={category} size="sm"></Breadcrumbs>
              <h1 className="text-left text-3xl">{product.title}</h1>
              <SeparatorComponent></SeparatorComponent>
              <div className="product-description">
                <Markdown >
                  {product.short_description}
                </Markdown>
                {isPrintInFrance(category.id) && (
                  <ul>
                    <li>
                      <span className="flex space-x-2 items-center">
                        <FrenchFlag className="w-4 h-4" />
                        <span>Imprimé en France</span>
                      </span>
                    </li>
                  </ul>
                )}
              </div>
              <div className="mt-4">
                <div className="bg-orange uppercase text-center text-white py-1 px-2 my-4 ">
                  <span>Votre sac marqué et livré en 7 jours ouvrés</span>
                </div>
                <div className="bg-blue text-white py-2 px-4">
                  <span>1. Choisissez la couleur de votre produit</span>
                </div>
                <div className="border-1 border-gray-200 text-gray-500 font-semibold py-2 px-4 ">
                  <span className="">* Sélectionnez une couleur : {color && color.Nom && color.Nom.replace(/[-_]/g, ' ')}</span>
                </div>
                <div className="border-1 border-gray-200 py-4 px-4 flex flex-wrap">
                  {product.variations &&
                    product.variations
                      .filter((v) => v.attributs_couleur)
                      .map((variation, index) => (
                        <span
                          title={variation.attributs_couleur.Nom}
                          key={index}
                          className={`block w-6 h-6 pt-1 mr-2 mt-3 rounded-full shadow border-blue ${variation.attributs_couleur.Nom}`}
                          style={{
                            backgroundColor: variation.attributs_couleur.valeur,
                          }}
                          onClick={(e) => {
                            slideRef.current.slickGoTo(index + 1);
                            setColor(variation.attributs_couleur);
                            setNoStock(variation.no_stock === true);
                          }}
                        >
                          {data.check && slideIndex === index + 1 && (<GatsbyImage
                            className={`${slideIndex === index + 1
                              ? "block w-4 pt-1 ml-1"
                              : "hidden"
                              }`}
                            image={getImage(data.check)}
                            alt="Check"
                          ></GatsbyImage>)}
                        </span>
                      ))}
                  {noStock === true && (<span className="bg-gray-100 mt-2 p-1 text-sm text-red-danger text-center">Cette couleur reviendra prochainement en stock. Désolé pour ce désagrément.</span>)}

                </div>
              </div>
              <div>
                <div className="bg-blue text-white py-2 px-4">
                  <span>2. Sélectionnez un type d'impression</span>
                </div>
                <div className="border-1 border-gray-200 text-gray-500 font-semibold py-2 px-4">
                  <span>* Choisir le marquage :</span>
                </div>
                <div className="border-1 border-gray-200 py-4 px-4">
                  <select
                    onChange={(e) => setMarquage(e.target.value)}
                    className="p-2 bg-white border-1 border-gray-200 shadow-inner"
                  >
                    <option>Choisir une option</option>
                    {product.attributs_marquages
                      .sort((a, b) => a.Nom < b.Nom ? -1 : 1)
                      .filter(a => !color || !(/quadri/i.test(a.Nom) && !/(ecru|blanc|beige|naturel)/i.test(color.Nom)))
                      .map((marquage, index) => (
                        <option key={index} value={marquage.Nom}>
                          {marquage.Nom}
                        </option>
                      ))}
                  </select>
                  <div className="flex space-x-6 items-center mt-4">
                    <div className="flex space-x-2 items-center" >
                      <input type="radio" className="w-4 mb-0 shadow-none" value={1} checked={faces === 1} onChange={updateFaces} id="1face" name="faces" />
                      <label htmlFor="1face">1 face</label>
                    </div>
                    <div className="flex space-x-2 items-center" >
                      <input type="radio" className="w-4 mb-0 shadow-none" value={2} checked={faces === 2} onChange={updateFaces} id="2face" name="faces" />
                      <label htmlFor="2face">2 faces</label>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div className="bg-blue text-white py-2 px-4">
                  <span>3. Choisir une quantité</span>
                </div>
                <div className="border-1 border-gray-200 text-gray-500 font-semibold py-2 px-4">
                  <span>* Commande de 100 pc. minimum :</span>
                </div>
                <div className="grid grid-rows-2 grid-cols-2 gap-0">
                  {quantities.map((qty, index) => (
                    <div
                      key={index}
                      className="flex space-x-2 border-1 border-gray-200 p-2 items-center"
                    >
                      <div className="w-4">
                        <input
                          onChange={updateQty}
                          checked={quantity === qty}
                          className="mb-0 h-6 shadow-none"
                          type="radio"
                          name="qty"
                          id={qty}
                          value={qty}
                        />
                      </div>
                      <label htmlFor={qty}>{qty}</label>
                    </div>
                  ))}
                </div>
                <div className="border-1 border-gray-200 py-2 px-4 flex space-x-4 items-center">
                  <span className="whitespace-nowrap">Autre quantité</span>
                  <input
                    name="quantity"
                    value={quantity}
                    onChange={updateQty}
                    type="number"
                    className="m-0 border-1 border-gray-200 shadow-inner"
                  />
                </div>
              </div>
              <div className="my-6 flex flex-col justify-center items-center">
                {noStock === true && (<span className="bg-gray-100 mt-2 p-1 text-sm text-red-danger text-center">Cette couleur reviendra prochainement en stock. Désolé pour ce désagrément.</span>)}

                <button
                  disabled={!color || !marquage || quantity < 100 || noStock}
                  className="my-2 py-3 px-8 bg-blue shadow-lg rounded-3xl uppercase text-xl text-white disabled:opacity-50"
                >
                  Demandez un devis
                </button>
                <a
                  className=" my-1 text-blue font-semibold underline"
                  href="mailto:devis@latelierdutotebag.com"
                >
                  devis@latelierdutotebag.com
                </a>
                <button
                  className=" my-1 text-blue font-semibold underline"
                  type="button"
                  onClick={() => setShowCall(true)}
                >
                  se faire rappeler
                </button>

              </div>
              <div className="flex justify-between">
                <div className="flex flex-col items-center justify-center shadow-sm px-2 py-1">
                  <div>
                    <GatsbyImage
                      style={{ width: '55px' }}
                      image={getImage(data.iconline1)}
                      alt="Devis en 24h"
                    />

                  </div>
                  <span className="text-sm font-semibold text-gray-500 uppercase">
                    Devis en 24h
                  </span>
                </div>
                <div className="flex flex-col items-center justify-center shadow-sm px-2 py-1">
                  <div>
                    <GatsbyImage
                      style={{ width: '70px' }}
                      image={getImage(data.iconline2)}
                      alt="Livraison 7 jours"
                    />
                  </div>
                  <span className="text-sm font-semibold text-gray-500 uppercase">
                    Livraison en 7 jours
                  </span>
                </div>
                <div className="flex flex-col items-center justify-center shadow-sm px-2 py-1">
                  <div>
                    <GatsbyImage
                      style={{ width: '60px' }}
                      image={getImage(data.iconline3)}
                      alt="Livraison offerte"
                    />

                  </div>
                  <span className="text-sm font-semibold text-gray-500 uppercase">
                    Livraison offerte
                  </span>
                </div>
              </div>
            </form>

            <form id="call-me" name="se-faire-rappeler" data-netlify="true" action="/remerciements" netlify-honeypot="potdemiel" className={showCall === true ? 'block' : 'hidden'}>
              <input type="hidden" name="form-name" value="se-faire-rappeler" />
              <p className="hidden">
                <label>Ne remplissez pas ce champ si vous êtes un humain: <input name="potdemiel" /></label>
              </p>
              <div className="flex items-end space-x-4">
                <div className="flex flex-col">
                  <label className="font-semibold text-gray-500">Renseignez votre numéro de téléphone*</label>
                  <input name="phone" type="text" className="form-control mb-0" />
                </div>
                <div>
                  <button className="btn btn-blue uppercase ">On m'appelle</button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <hr className="my-14" id="totebag-informations" />
        <div className="flex">
          <div className="md:w-3/12">
            <ul>
              <li
                className={`p-3 border-b-1 border-gray-200 ${activeTab === 0 ? "border-r-3" : ""
                  }`}
              >
                <a
                  href="#totebag-informations"
                  onClick={() => showTab(0)}
                  className={`text-sm text-gray-500 uppercase font-semibold ${activeTab === 0 ? "text-blue underline" : ""
                    }`}
                >
                  Description
                </a>
              </li>
              <li
                className={`p-3 border-b-1 border-gray-200 ${activeTab === 1 ? "border-r-3" : ""
                  }`}
              >
                <a
                  href="#totebag-informations"
                  onClick={() => showTab(1)}
                  className={`text-sm text-gray-500 uppercase font-semibold ${activeTab === 1 ? "text-blue underline" : ""
                    }`}
                >
                  Informations complémentaires
                </a>
              </li>
            </ul>
          </div>
          <div className="md:w-9/12 border-l-1 border-gray-200 pl-4">
            <div className={activeTab === 0 ? "block" : "hidden"}>
              <Markdown >{product.short_description}</Markdown>
            </div>
            <div className={activeTab === 1 ? "block" : "hidden"}>
              <div className="flex space-x-6 py-2 border-b-1 border-gray-200">
                <div className="w-3/12">
                  <span className="font-semibold text-sm">Couleurs</span>
                </div>
                <div className="w-9/12 flex space-x-2">
                  <span className="text-sm capitalize">
                    {couleurs
                      .map((c) => c.replace("-", " "))
                      .map((c, i, a) => (i === a.length - 1 ? c : c + ", "))}
                  </span>
                </div>
              </div>
              {attributes &&
                attributes.map((attribute, index) => (
                  <div
                    key={index}
                    className="flex space-x-6 py-2 border-b-1 border-gray-200"
                  >
                    <div className="w-3/12">
                      <span className="font-semibold text-sm">
                        {attribute[0]}
                      </span>
                    </div>
                    <div className="w-9/12 flex space-x-2">
                      <span className="text-sm">
                        {attribute[2] && attribute[2] === 1
                          ? product[attribute[1]].map((v, i, a) =>
                            i === a.length - 1 ? v.Nom : v.Nom + ", "
                          )
                          : product[attribute[1]] && product[attribute[1]].Nom}
                      </span>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
        <hr className="my-8" />
        <div>
          <h2 className="uppercase">Produits apparentés</h2>
          <ProductList products={products} cols={4}></ProductList>
        </div>
      </div>
    </Layout>
  );
});


export default Product;
