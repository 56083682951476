import "@fontsource/baskervville";
import { graphql, StaticQuery } from "gatsby";
import PropTypes from "prop-types";
import React from "react";
import "../../node_modules/slick-carousel/slick/slick-theme.css";
import "../../node_modules/slick-carousel/slick/slick.css";
import "../assets/css/main.css";
import Footer from "./navs/footer";
import Header from "./navs/header";
import Seo from "./seo";


const Layout = ({ title, description, children, seo, showHero = false, containered = true, heroImage, productAdded }) => {

  if (typeof window !== 'undefined' && window.document) {
    (function (w, d, s, l, i) {
      w[l] = w[l] || []; w[l].push({
        'gtm.start':
          new Date().getTime(), event: 'gtm.js'
      }); var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
          'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', 'GTM-NNS4CK');

    const iframe = document.createElement('iframe');
    iframe.setAttribute('src', "https://www.googletagmanager.com/ns.html?id=GTM-NNS4CK")
    iframe.setAttribute('width', 0)
    iframe.setAttribute('height', 0)
    iframe.setAttribute('style', "display:none;visibility:hidden")
    const body = document.body;
    body.insertBefore(iframe, body.children[0]);
  }

  if (false && typeof window !== 'undefined' && window.document && !window.axeptioSettings) {
    window.axeptioSettings = {
      clientId: "61c04a0fc4adc8428cecaea6",
      cookiesVersion: "latelierdutotebag.com-base",
    };

    (function (d, s) {
      var t = d.getElementsByTagName(s)[0], e = d.createElement(s);
      e.async = true; e.src = "//static.axept.io/sdk.js";
      t.parentNode.insertBefore(e, t);

      void 0 === window._axcb && (window._axcb = []);
      window._axcb.push(function (axeptio) {
        axeptio.on("cookies:complete", function (choices) {


        })
      })
    })(document, "script");


  }

  return (
    <StaticQuery
      query={graphql`
      query {
        strapiHomepage {
          seo {
            metaTitle
            metaDescription
            shareImage {
              localFile {
                publicURL
              }
            }
          }
        }
      }
    `}
      render={(data) => (
        <>
          {/* do not remove - use for ab testing */}
          <div id="branch" version={process.env.BRANCH} />
          <Seo seo={{ ...data.strapiHomepage.seo, ...seo }} />
          <Header title={title} description={description} productAdded={productAdded} showBanner={showHero} heroImage={heroImage}></Header>
          <main className={`relative ${containered ? 'container' : ''}`}>{children}</main>
          <Footer />

        </>
      )}
    />);
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
