import React from "react";
import { navigate } from "gatsby";

const ObfuscatedLink = ({ to, className, children }) => {

  const encodeUrl = url => btoa(url)
  const decodeUrl = url => atob(url)

  const encodedUrl = encodeUrl(to);

  const handleClick = (event) => {
    if (!window)
      return;
    event.preventDefault();
    const decodedUrl = decodeUrl(encodedUrl);
    navigate(decodedUrl);
  };

  return (
    <span className={`a-link cursor-pointer ${className}`} onClick={handleClick} >
      {children}
    </span>
  );
};

export default ObfuscatedLink;
